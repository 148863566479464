import "@amelia-genesis/common/utilities/styles/globals.scss";
import type { AppProps } from "next/app";
import Providers from "@amelia-genesis/common/tripface/Providers";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Providers>
      <Component {...pageProps} />
    </Providers>
  );
}
