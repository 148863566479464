import { createContext, FC, ReactNode, useContext } from 'react';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";


import { IPublicAnalyticsContext } from "@amelia-genesis/api";
import { captureError } from "@amelia-genesis/api/utilities/sentryUtilities";
import {AnalyticsReportingMode, IAnalyticsEventParameters} from "./analyticsInterfaces";
import {trackGoogleAnalyticsEvent, useInitGoogleAnalytics} from "./GoogleAnalytics";
import {appInsightsReactPlugin, trackAppInsightsEvent, useInitAppInsights} from "./AppInsightsAnalytics";
import {clarityCallEvent, useInitClarityAnalytics} from "./ClarityAnalytics";
import Clarity from "./Clarity";
interface AnalyticsProviderProps {
  children: ReactNode;
  analyticsContext?: IPublicAnalyticsContext
}

interface IAnalyticsContextProps {
  analyticsContext?: IPublicAnalyticsContext;
}

const isDebug = process.env.NODE_ENV === "development";

export const trackConversion = (eventName: string, parameters: IAnalyticsEventParameters): void => {
  trackEvent(eventName, parameters, AnalyticsReportingMode.ConversionOnly);
}

export const trackEvent = (eventName: string, parameters: IAnalyticsEventParameters, mode: AnalyticsReportingMode = AnalyticsReportingMode.ConversionAndEvent): void => {
  if (isDebug) {
    console.log(`Analytics event: ${eventName} (Mode: ${mode})`, parameters);
  }

  try {
    trackGoogleAnalyticsEvent(eventName, parameters, mode);
  } catch (err) {
    captureError(err);
  }

  if (mode != AnalyticsReportingMode.ConversionOnly) {
    try {
      trackAppInsightsEvent(eventName, parameters);
    } catch (err) {
      captureError(err);
    }

    try {
      clarityCallEvent(eventName);
    } catch (err) {
      captureError(err);
    }
  }
}

const AnalyticsContext = createContext<IAnalyticsContextProps | undefined>(undefined);

const AnalyticsProvider : FC<AnalyticsProviderProps> = ({children, analyticsContext}) => {
  useInitAppInsights(analyticsContext);
  useInitGoogleAnalytics(analyticsContext);
  useInitClarityAnalytics(analyticsContext);

  return (
    <AnalyticsContext.Provider value={{ analyticsContext }}>
      <AppInsightsContext.Provider value={appInsightsReactPlugin}>
        <Clarity />
        {children}
      </AppInsightsContext.Provider>
    </AnalyticsContext.Provider>
  );
}

export default AnalyticsProvider;

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within a AnalyticsProvider');
  }
  return context;
}
