import { captureError } from "@amelia-genesis/api/utilities/sentryUtilities";

class DelayedAnalyticsActions {
  private actionsCallbacks: Array<() => void> = [];

  private isInitialized: boolean = false;

  public markInitialized() {
    this.isInitialized = true;
    this.actionsCallbacks.forEach((callback) => {
      try {
        callback();
      } catch (e) {
        captureError(e);
      }
    });
    this.actionsCallbacks = [];
  }

  public performAction(callback: () => void) {
    if (this.isInitialized) {
      callback();
    } else {
      this.actionsCallbacks.push(callback);
    }
  }
}

const storage : Map<string, DelayedAnalyticsActions> = new Map<string, DelayedAnalyticsActions>();

export const getDelayedActions = (key: string) : DelayedAnalyticsActions => {
  let oldValue = storage.get(key);

  if (!oldValue) {
    oldValue = new DelayedAnalyticsActions();
    storage.set(key, oldValue);
  }

  return oldValue;
}
