import { twMerge } from "tailwind-merge";

export const ignoredErrorMessages = [
  // 'AbortError: Fetch is aborted',
  // 'Failed to fetch',
  // 'Load failed'
];

export const isHeader = (str: string): boolean => {
  const regex = RegExp(/h[1-5]/g);
  return regex.test(str);
};

export const shouldReportError = (error: unknown) => {
  if (error instanceof Error) {
    const errorMessage = error.message;
    const errorName = error.name;
    const isMessageIgnored = ignoredErrorMessages.some(ignoredMessage => errorMessage === ignoredMessage);
    const shouldReport = !isMessageIgnored;

    console.log("shouldReportError", errorMessage, errorName, shouldReport);
    return shouldReport;
  }
  console.warn("Unknown error type", error);
  return true;
};

export function classNames(...classes: (string | undefined | null | false | 0)[]) {
  // Keep only non-empty strings
  const filteredClasses = classes.filter((c) => typeof c === "string" && c.trim().length > 0);
  if (filteredClasses.length === 0) {
    return undefined;
  }

  if (filteredClasses.length === 1) {
    return filteredClasses[0] as string;
  }

  return twMerge(filteredClasses);
}

export function arrayToRecord<Key extends PropertyKey, Value>(
  array: Value[],
  keyReader: (value: Value) => Key
): Record<Key, Value> {
  return array.reduce((record, value) => {
    const key = keyReader(value);
    record[key] = value;
    return record;
  }, {} as Record<Key, Value>);
}

export const getNumberToFixed = (number: number, fixed: number = 0): number => {
  return Number(number.toFixed(fixed));
}

export const strToBase64 = (str: string): string => {
  return Buffer.from(str, "utf-8").toString("base64");
};

export const base64ToStr = (str: string): string => {
  return Buffer.from(str, 'base64').toString('utf-8');
};
