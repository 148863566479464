import { FC } from "react";
import Head from 'next/head';
import { websiteColorPalette, websiteInfo } from "@amelia-genesis/website/config/websiteConfig";

const SeoMetadata: FC = () => {
  const title = websiteInfo.websiteName;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <title>{title}</title>
        <meta property="og:title" content={title}/>
        <meta name="twitter:title" content={title}/>
        <meta itemProp="name" content={title}/>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"/>
        <meta name="msapplication-TileColor" content={websiteColorPalette.primary}/>

        <meta name="theme-color" content="#ffffff"/>

        <meta property="og:type" content="website"/>
      </Head>
    </>
  )
};

export default SeoMetadata;