"use client";

import React, { ReactNode } from 'react';

import FallbackComponent from "@amelia-genesis/common/components/errors/FallbackComponent";
import { captureError } from "@amelia-genesis/api/utilities/sentryUtilities";

interface ErrorBoundaryProps {
  fallback?: ReactNode;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class BuddyErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    // Logs the error to Sentry directly without a new scope
    captureError(error);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      } else {
        return (<FallbackComponent />)
      }

    }

    return this.props.children;
  }
}

export default BuddyErrorBoundary;
