import { FC, ReactNode } from "react";
import SeoMetadata from "./SeoMetadata";
import BackToTop from "../components/templete/Layout/BackToTop";
import AosAnimation from "../components/templete/Layout/AosAnimation";
import 'swiper/css/bundle';
import IntlProvider from "@amelia-genesis/i18n/IntlProvider";
import AnalyticsProvider from "@amelia-genesis/analytics/AnalyticsProvider";
import { Figtree, Outfit } from "next/font/google";
import BuddyErrorBoundary from "@amelia-genesis/common/components/errors/BuddyErrorBoundary";

const figtree = Figtree({
  subsets: ["latin"],
  variable: "--font-figtree",
  display: "swap",
});

const outfit = Outfit({
  subsets: ["latin"],
  variable: "--font-outfit",
  display: "swap",
});


interface IProviders {
  children: ReactNode;
}

const Providers: FC<IProviders> = ({ children }) => {

  return (
    <main className={`${figtree.variable} ${outfit.variable}`}>
      <IntlProvider>
        <AnalyticsProvider>
          <SeoMetadata/>
          {/* BackToTop */}
          <BackToTop />

          {/* AosAnimation */}
          <AosAnimation />
          <BuddyErrorBoundary>
            {children}
          </BuddyErrorBoundary>
        </AnalyticsProvider>
      </IntlProvider>
    </main>
  )
};

export default Providers;