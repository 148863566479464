import Cookies, {CookieAttributes} from "js-cookie";

const year = 365 * 24 * 60 * 60 * 1000;

const isLocalhost = (hostname: string) => {
  // Check if the hostname is 'localhost' or an IP address
  return hostname === 'localhost' || /^\d{1,3}(\.\d{1,3}){3}$/.test(hostname)
}

const getTopLevelDomain = (hostname: string): string => {
  // Check if the hostname is 'localhost' or an IP address
  if (isLocalhost(hostname)) {
    return hostname;
  }

  // Split the hostname into parts
  const parts = hostname.split('.');

  // Return the last two parts joined by a dot, which should be the top-level domain
  const plainDomain = parts.length > 1 ? `${parts[parts.length - 2]}.${parts[parts.length - 1]}` : hostname;
  return `.${plainDomain}`;
};

export const getDefaultCookieAttributes = (expiration: number): CookieAttributes => {

  let domain: string;

  if (typeof window !== 'undefined') {
    domain = getTopLevelDomain(window.location.hostname)
  } else {
    const envHostname = process.env.NEXTAUTH_URL?.split("//")[1] ?? "localhost"

    if (isLocalhost(envHostname)) {
      domain = envHostname;
    } else {
      domain = `${getTopLevelDomain(envHostname)}`
    }
  }

  return {
    expires: new Date(Date.now() + expiration),
    domain: domain,
    path: '/'
  };
}

export const setClientCookie = (name: string, value: string, expiration: number = year * 10) => {
  Cookies.set(name, value, getDefaultCookieAttributes(expiration));
}

export const getClientCookie = (name: string): string | undefined => {
  return Cookies.get(name);
}
