import { IWebsiteColorPalette, IWebsiteInfo } from "@amelia-genesis/website";

export const websiteInfo: IWebsiteInfo = {
  websiteName: "AmeliaGenesis",
  supportAddress: "8 The Green, Suite #13024, Dover, DE 19901",
  supportEmail: "support@ameliagenesis.com"
}

export const websiteColorPalette: IWebsiteColorPalette = {
  white: "#ffffff",
  primary: "#EF4335",
  brand: "#e7434e",
  lightGrey: "#f5f3fd",
  hoverGrey: "#FAF4F4",
  secondaryGrey: "#DCE1EA"
}