import Link, { LinkProps } from "next/link";
import React, { FC } from "react";

export interface NoPrefetchLinkProps extends LinkProps {
  prefetch?: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  target?: string;
  rel?: string;
  href: string;
}

/**
 * @obsolete Use KumaLink instead
 * @param props
 * @constructor
 */
export const NoPrefetchLink: FC<NoPrefetchLinkProps> = (props) => {
  const {
    prefetch = false,
    children,
    target,
    style,
    rel,
    href,
    ...otherProps
  } = props;
  if (href == "#") {
    return (<a href={href} {...otherProps} style={{...style,
      pointerEvents: "none",
      cursor: "pointer"
    }} onClick={(event) => {event.preventDefault()}}>
      {children}
    </a>);
  }

  return (
    <Link href={href} {...otherProps} rel={rel} prefetch={prefetch} style={style} target={target}>
      {children}
    </Link>
  );
};
