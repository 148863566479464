


export type IAnalyticsEventParameters = {
  [key: string]: string | number | boolean | IAnalyticsEventParameters | IAnalyticsEventParameters[];
};

export enum AnalyticsReportingMode {
  ConversionAndEvent = 1,
  EventOnly = 2,
  ConversionOnly = 3,
}
