
export const themeBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1350,
}


export const themeBreakpointsMedia = {
  sm: `@media (max-width:${themeBreakpoints.sm}px)`,
  md: `@media (max-width:${themeBreakpoints.md}px)`,
  lg: `@media (max-width:${themeBreakpoints.lg}px)`,
  xl: `@media (max-width:${themeBreakpoints.xl}px)`,
  xxl: `@media (max-width:${themeBreakpoints.xxl}px)`,
}
