"use client";
import * as Sentry from "@sentry/nextjs";
import { shouldReportError } from "@amelia-genesis/library";

export const captureError = (error: unknown) => {
  if (shouldReportError(error)) {
    console.error("CapturedError", error);
    Sentry.captureException(error);
  } else {
    console.warn(error)
  }
}