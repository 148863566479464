import ReactGA from 'react-ga4';
import { IPublicAnalyticsContext } from "@amelia-genesis/api";
import {AnalyticsReportingMode, IAnalyticsEventParameters} from "./analyticsInterfaces";
import {useStableEffect, setClientCookie} from "@amelia-genesis/library";
import {getDelayedActions} from "./DelayedAnalyticsActions";

const isProduction = process.env.NODE_ENV === "production";

const delayedId = "GoogleAnalytics";

// string -> string map


const currentMapping: { [key: string]: string } = {};

const isDebug = process.env.NODE_ENV === "development";

export const trackGoogleAnalyticsEvent = (eventName: string, parameters: IAnalyticsEventParameters, mode: AnalyticsReportingMode): void => {
  getDelayedActions(delayedId).performAction(() => {
    if (mode != AnalyticsReportingMode.ConversionOnly) {
      ReactGA.event(eventName, parameters);
    }


    if (currentMapping[eventName] && mode != AnalyticsReportingMode.EventOnly) {
      const conversionName = eventName == "purchase" ? "purchase" : "conversion"

      const newArgs = {
        'send_to': currentMapping[eventName],
        ...parameters
      };

      ReactGA.event(conversionName, newArgs);

      if (isDebug) {
        console.log("Log conversion", conversionName, newArgs);
      }
    }
  });
}

export const useInitGoogleAnalytics = (analyticsContext?: IPublicAnalyticsContext) => {

  useStableEffect(() => {
    const trackingId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID as string;

    const analyticsUserId = analyticsContext?.userId || analyticsContext?.authenticatedUserId;

    const userIdInfo = analyticsUserId ? { 'user_id': analyticsUserId } : {};

    const attributionInfo = analyticsContext?.attribution ? {
      "user_properties": {
        'tb_medium': analyticsContext.attribution.medium,
        'tb_source': analyticsContext.attribution.source,
        'tb_source_id': analyticsContext.attribution.sourceId || "",
        'tb_campaign_id': analyticsContext.attribution.campaignId || "",
        'tb_term': analyticsContext.attribution.term || "",
        'tb_re_medium': analyticsContext.attribution.retargetMedium || "",
        'tb_re_source': analyticsContext.attribution.retargetSource || "",
        // 'tb_device': getPricingDeviceName(analyticsContext.pricingDevice),
        'guest_user_id': analyticsContext.userId || "",
        'auth_user_id': analyticsContext.authenticatedUserId || "",
        'authenticated': analyticsContext.isAuthenticated,
        'country_code': analyticsContext.countryCode || "",
        'language_code': analyticsContext.languageCode,
        'user_device': analyticsContext.attribution.userDevice || "",
      }} : {};

    // Initialize Google Analytics with your GA measurement ID
    ReactGA.initialize([
      {
        trackingId: trackingId,

        // Add any additional options if needed
      }
    ], {
      gtagOptions: {
        //transport: 'beacon',
        debug_mode: !isProduction,
        ...userIdInfo,
        ...attributionInfo,
      }
    });

    // Set user ID and attribution data if available
    if (analyticsContext) {
      if (analyticsUserId) {
        ReactGA.set(userIdInfo);
      }

      if (analyticsContext.attribution) {
        ReactGA.set(attributionInfo);
      }
    }

    ReactGA.gtag('get', trackingId, 'client_id', (clientID: string) => {
      setClientCookie('tf_ga_client_id', clientID);
    });
    ReactGA.gtag('get', trackingId, 'session_id', (clientID: string) => {
      setClientCookie('tf_ga_session_id', clientID);
    });

    getDelayedActions(delayedId).markInitialized();
  }, [analyticsContext]);


};
