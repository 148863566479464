
import { IPublicAnalyticsContext } from "@amelia-genesis/api";
import {useStableEffect} from "@amelia-genesis/library";
// import {getPricingDeviceName} from "./analyticsConstants";
import {getDelayedActions} from "./DelayedAnalyticsActions";


// Extend the Window interface
declare global {
  interface Window {
    clarity?: (...args: unknown[]) => void;
  }
}

const delayedId = "ClarityAnalytics";


const clarityCallIdentify = (customId: string, customSessionId?: string, customPageId?: string, friendlyName?: string) => {
  if (window.clarity) {
    window.clarity('identify', customId, customSessionId, customPageId, friendlyName);
  }
}

const performClarityCallSet = (key: string, value: string | number) => {
  if (window.clarity) {
    window.clarity('set', key, value);
  }
}

export const clarityCallSet = (key: string, value: string | number) => {
  getDelayedActions(delayedId).performAction(() =>
    performClarityCallSet(key, value)
  );
}

export const claritySaveSession = (session: string) => {
  getDelayedActions(delayedId).performAction(() => {
    if (window.clarity) {
      window.clarity('upgrade', session);
    }
  });
}

export const clarityCallEvent = (eventName: string) => {
  getDelayedActions(delayedId).performAction(() => {
    if (window.clarity) {
      window.clarity('event', eventName);
    }
  });
}


export const useInitClarityAnalytics = (analyticsContext?: IPublicAnalyticsContext) => {

  useStableEffect(() => {
    if (analyticsContext) {
      const analyticsUserId = analyticsContext.userId || analyticsContext.authenticatedUserId;

      if (analyticsUserId) {
        clarityCallIdentify(analyticsUserId);
        performClarityCallSet('tb_user_id', analyticsUserId);
      }

      if (analyticsContext.attribution) {
        const attribution = analyticsContext.attribution;
        performClarityCallSet('tb_medium', attribution.medium);
        performClarityCallSet('tb_source', attribution.source);

        performClarityCallSet('tb_source_id', attribution.sourceId || "");
        performClarityCallSet('tb_campaign_id', attribution.campaignId || "");

        performClarityCallSet('tb_term', attribution.term || "");
        // performClarityCallSet('tb_device', getPricingDeviceName(analyticsContext.pricingDevice));

        if (analyticsContext.attribution.userDevice) {
          performClarityCallSet('user_device', analyticsContext.attribution.userDevice);
        }

        if (analyticsContext.attribution?.retargetMedium) {
          performClarityCallSet('tb_re_medium', analyticsContext.attribution.retargetMedium)
        }

        if (analyticsContext.attribution?.retargetSource) {
          performClarityCallSet('tb_re_source', analyticsContext.attribution.retargetSource)
        }

        if (analyticsContext.authenticatedUserId) {
          performClarityCallSet('auth_user_id', analyticsContext.authenticatedUserId);
        }

        if (analyticsContext.countryCode) {
          performClarityCallSet('country_code', analyticsContext.countryCode);
        }
        performClarityCallSet('language_code', analyticsContext.languageCode);

        performClarityCallSet('authenticated', `${analyticsContext.isAuthenticated}`);
      }
    }

    getDelayedActions(delayedId).markInitialized();
  }, [analyticsContext]);

}
