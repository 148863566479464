import {useEffect, DependencyList, EffectCallback, useState, Dispatch, useRef, useCallback} from 'react';
import {isEqual} from 'lodash';


function useDeepCompareMemoize<T>(value: T) {
  const ref = useRef<T>()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  const dependenciesEqual = isEqual(value, ref.current);

  // if (!dependenciesEqual) {
  //   console.warn('dependenciesEqual', dependenciesEqual, value, ref.current);
  // }

  if (!dependenciesEqual) {
    ref.current = value
  }

  return ref.current
}



export const useStableEffect = (effect: EffectCallback, dependencies: DependencyList): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect,
    dependencies.map(useDeepCompareMemoize));
}

export const useStableState = <T>(
  initialState: T
): [T, Dispatch<T>] => {
  const [state, setState] = useState(initialState);
  const lastSetStateRef = useRef<T>(initialState);
  const setStateCalledRef = useRef<boolean>(false);

  const setStableState = useCallback((newState: T) => {

    if (!isEqual(newState, lastSetStateRef.current)) {
      setStateCalledRef.current = true;
      lastSetStateRef.current = newState;
      setState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, setStableState];
};

