"use client";
import React from 'react';
import { useCommonTranslations } from "@amelia-genesis/common";
import { NoPrefetchLink } from "@amelia-genesis/common/components/link/NoPrefetchLink";

const FallbackComponent: React.FC = () => {
  const { tCommon } = useCommonTranslations();
  const refreshPage = (e: React.MouseEvent) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  }

  return (
    <>
      <span className="text-danger">
        {tCommon("errorMessageGeneral")}
        {' '}
        <NoPrefetchLink href={""} style={{ cursor: 'pointer' }} onClick={refreshPage}>
          {tCommon("errorMessageReloadPage")}
        </NoPrefetchLink>
      </span>
    </>
  );
}

export default FallbackComponent;
