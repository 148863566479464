import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import { IPublicAnalyticsContext } from "@amelia-genesis/api";
import { IAnalyticsEventParameters } from './analyticsInterfaces';
import {getDelayedActions} from "./DelayedAnalyticsActions";
import {useStableEffect} from "@amelia-genesis/library";

const appInsightsKey = process.env.NEXT_PUBLIC_APP_INSIGHTS;

let appInsights: ApplicationInsights;

const reactPlugin = new ReactPlugin();
if (appInsightsKey) {
  appInsights = new ApplicationInsights({
    config: {
      connectionString: process.env.NEXT_PUBLIC_APP_INSIGHTS,
      enableAutoRouteTracking: true,
      disableAjaxTracking: true,
      disableFetchTracking: true,
      isStorageUseDisabled: true,
      //isBeaconApiDisabled: false,
      disableExceptionTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
} else {
  console.error("App Insights key is not set");
}

const delayedId = "AppInsights";

export const trackAppInsightsEvent = (eventName: string, parameters: IAnalyticsEventParameters): void => {
  getDelayedActions(delayedId).performAction(() => {
    appInsights.trackEvent({ name: eventName, properties: parameters });
  });
}

export const useInitAppInsights = (analyticsContext?: IPublicAnalyticsContext) => {
  useStableEffect(() => {
    if (analyticsContext) {
      const analyticsUserId = analyticsContext.userId || analyticsContext.authenticatedUserId;

      if (analyticsUserId) {
        appInsights.context.user.id = analyticsUserId;
      }

      if (analyticsContext.attribution) {
        const attribution = analyticsContext.attribution;
        appInsights.addTelemetryInitializer(envelope => {
          const item = envelope.baseData;
          if (item) {
            item.properties = item.properties || {};
            item.properties.tb_medium = attribution.medium;
            item.properties.tb_source = attribution.source;

            item.properties.tb_source_id = attribution.sourceId || "";

            item.properties.tb_campaign_id = attribution.campaignId || "";
            item.properties.tb_term = attribution.term || "";

            if (analyticsContext.userId) {
              item.properties.guest_user_id = analyticsContext.userId;
            }

            if (analyticsContext.authenticatedUserId) {
              item.properties.auth_user_id = analyticsContext.authenticatedUserId;
            }

            if (analyticsContext.countryCode) {
              item.properties.country_code = analyticsContext.countryCode;
            }
            item.properties.language_code = analyticsContext.languageCode;

            item.properties.authenticated = analyticsContext.isAuthenticated;

            // item.properties.tb_device = getPricingDeviceName(analyticsContext.pricingDevice);

            if (analyticsContext.attribution?.userDevice) {
              item.properties.user_device = analyticsContext.attribution.userDevice;
            }

            if (analyticsContext.attribution?.websiteName) {
              item.properties.tb_website = analyticsContext.attribution.websiteName
            }

            if (analyticsContext.attribution?.retargetMedium) {
              item.properties.tb_re_medium = analyticsContext.attribution.retargetMedium
            }

            if (analyticsContext.attribution?.retargetSource) {
              item.properties.tb_re_source = analyticsContext.attribution.retargetSource
            }
          }
        });
      }
      getDelayedActions(delayedId).markInitialized();
    }
  }, [analyticsContext]);
}

export const appInsightsReactPlugin = reactPlugin;
