import { FC, ReactNode } from "react";
import { NextIntlClientProvider } from "next-intl";
import commonMessages from "@amelia-genesis/i18n/localization/en/ModuleCommon.json";
import guideMessages from "@amelia-genesis/i18n/localization/en/ModuleGuide.json";
import { merge } from "lodash";

const messages = merge({}, commonMessages, guideMessages);

interface IntlProviderProps {
  children: ReactNode;
}

const IntlProvider: FC<IntlProviderProps> = ({ children }) => {
  return (
    <NextIntlClientProvider
      messages={messages}
      locale={"en"}
      timeZone={"America/New_York"}
    >
      {children}
    </NextIntlClientProvider>
  )
};

export default IntlProvider;